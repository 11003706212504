<template lang="">
  <div class="bar">
    <Header class="hide" />
    <Headers class="dispy" />
    <BannerImage rulename="query_banner" />
    <div class="w">
      <Breadcrumb :crumblist="crumbdata" class="hide" />
      <div class="strip">
        <div class="car">
          搜索
        </div>
      </div>
      <div class="navig-box">
        <ul class="navig-list">
          <li class="navig-item" v-for="item in result" :key="item.searchKey">
            <el-row :gutter="26">
              <el-col :md="6">
                <img
                  v-if="item.pic"
                  :src="`/jeecg-boot/${item.pic}`"
                  class="imgleft"
                />
                <img
                  v-else
                  src="@/assets/image/main/zanwutp.png"
                  alt=""
                  class="imgleft"
                />
              </el-col>
              <el-col :md="18">
                <div class="navig-info">
                  <router-link :to="{ path: item.router, query: { id: item.id } }">
                    <h4 class="title">{{ item.title }}</h4>
                    <p class="desc">{{ item.info }}</p>
                    <p class="time">
                      <i class="fa fa-long-arrow-right arrow lr"></i>
                    </p>
                  </router-link>
                </div>
              </el-col>
            </el-row>
          </li>
        </ul>
        <!-- <Pagi
      :total="pagi.total"
      :current="pagi.current"
      :sizes="pagi.size"
      @pagicurrent="togglePagi"
    /> -->
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Breadcrumb from "@/components/main/Breadcrumb";
import Header from "@/components/main/Header";
import Headers from "@/components/wap/Headers";
import Footer from "@/components/main/Footer";
import OnerowLists from "@/components/main/OnerowLists";
import BannerImage from "@/components/main/BannerImage";
import { getAction } from "@/api/manage";

export default {
  components: {
    Breadcrumb,
    Header,
    Footer,
    OnerowLists,
    BannerImage,
    Headers,
  },
  created() {
    this.loadData();
  },
  data() {
    return {
      result: {},
      crumbdata: [{ name: "搜索", url: "" }],
    };
  },
  methods: {
    loadData() {
      getAction("/search/queryBySearchKey", {
        searchKey: this.$route.query.keyword,
      }).then((res) => {
        if (res.success) {
          this.result = res.result[0];
          // this.crumbdata.push({
          //   name: res.result.theTitle,
          // });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.navig-box {
  margin-bottom: 50px;
  padding: 0 18px 0 18px;
  .navig-list {
    list-style: none;
    min-height: 400px;
    .navig-item {
      border-bottom: 1px dashed #dbdbdb;
      padding: 25px 0;
      .imgleft {
        width: 100%;
        height: 184px;
      }
      .navig-info {
        padding: 10px 0 9px;
        .title {
          font-size: 24px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          transition: all 0.5s;
        }
        .desc {
          margin: 20px 0;
          height: 78px;
          font-size: 14px;
          line-height: 26px;
          color: #999999;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          overflow: hidden;
          text-align: justify;
        }
        .time {
          color: #999999;
          font-size: 14px;
          .arrow {
            font-size: 20px;
            transition: all 0.5s;
          }
        }
      }
      &:hover {
        .title {
          color: #6EB400;
        }
        .time {
          .arrow {
            color: #6EB400;
          }
        }
      }
    }
  }
}
</style>
